
import 'swiper/swiper-bundle.min.css';
import '@ionic/vue/css/ionic-swiper.css';

// icons
import { image, eye } from 'ionicons/icons';

// components
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { IonicSwiper, IonList, IonListHeader, IonImg, IonText, IonIcon, modalController, } from '@ionic/vue';

import ImageModal from './ImageModal.vue';

SwiperCore.use([IonicSwiper, Navigation, Pagination, Autoplay]);

export default {
  props: [
    "defaultCaption",
    "listHeader",
    "imageLinks",
    "photos",
    "minSlidesPerView",
    "size",
    "hideEyeIcon",
  ],
  components: { Swiper, SwiperSlide, IonList, IonListHeader, IonImg, IonText, IonIcon, },
  setup(props: any) {
    const minSlidesPerView = props.minSlidesPerView || 1;
    const ops = {
      slidesPerView: Math.max(minSlidesPerView, 1),
      breakpoints: {
        768: { slidesPerView: Math.max(minSlidesPerView, 3), },
        1024: { slidesPerView: Math.max(minSlidesPerView, 4), }
      },
    };
    const openImageModal = async (imageLink: any, caption: any) => {
      const modal = await modalController.create({
        component: ImageModal,
        componentProps: { imageLink, caption },
        swipeToClose: true,
      });
      return modal.present();
    }
    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      image, eye,

      // variables
      ops,

      // methods
      openImageModal
    }
  }
}
